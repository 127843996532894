import tableMixin from '@/minxin/table-minxin'
import Adsearch from '@/components/Adsearch'
import PageHeader from '@/components/PageHeader'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import $post from '@/request/productApis.js'
import root from '@/minxin/root'
const myApproval = {
  name: 'MyApproval',
  mixins: [tableMixin, root],
  components: {
    Adsearch,
    PageHeader,
    ElImageViewer
  },
  data() {
    return {
      root_name: 'spzx',
      root_msg: '审批中心',
      isBTn: false,
      showViewer: false,
      MaterialFileList: [],
      input_value: '',
      process_list: [{ name: '审批编号', value: 'ApprovalCode', icon: 'el-icon-bell' }, { name: '流程状态', value: 'StatusString', icon: 'el-icon-position' }, { name: '审批类型', value: 'ApprovalTypeString', icon: 'el-icon-mobile' }, { name: '申请部门', value: 'ApprovalApplyDepartment', icon: 'el-icon-coordinate' }, { name: '申请人', value: 'ApprovalApplyUser', icon: 'el-icon-user' }, { name: '发起时间', value: 'BeginTimeString', icon: 'el-icon-watch' }],
      adSearchForm: [
        { name: '审批编号', value: 'ApprovalCode' },
        { name: '审批类型', value: 'ApprovalType' },
        { name: '审批名称', value: 'ApprovalName' },
        { name: '申请部门', value: 'ApprovalApplyDepartment' },
        { name: '申请人', value: 'ApprovalApplyUser' },
        { name: '发起时间', date: true, value: 'ApprovalDate' }
      ],
      numList: {},
      formSearch: {
        ApprovalCode: '',
        ApprovalType: '',
        ApprovalName: '',
        ApprovalApplyDepartment: '',
        ApprovalApplyUser: '',
        ApprovalDate: ''
      },
      tableData: [],
      searchWords: {
        ApprovalDate: '',
        KeyWords: '',
        ApprovalCode: '',
        ApprovalType: 0,
        ApprovalName: '',
        ApprovalApplyDepartment: '',
        ApprovalApplyUser: '',
        BeginTime: '',
        EndTime: '',
        Status: 1
      },
      isShow: false,
      activeName: '1',
      selects: [],
      ApprovalRecordUserDetailsIDList: '',
      showElement: false,
      activeName_business: 'first',
      activities: [{
        content: '支持使用图标',
        timestamp: '2018-04-12 20:46',
        color: '#0bbd87'
      }, {
        content: '支持自定义颜色',
        timestamp: '2018-04-03 20:46',
        color: '#0bbd87'
      }, {
        content: '支持自定义尺寸',
        timestamp: '2018-04-03 20:46',
        color: '#0bbd87'
      }],
      formJson: [],
      dataJson: {},
      RecordList: [],
      RecordInfo: { RecordInfo: {}},
      ChatList: {},
      chat_value_length: 0,
      sendMsgForm: {
        RecordID: '',
        MessageType: '',
        FileExtName: '',
        Message: '',
        AtUserID: '',
        Reason: ''
      },
      base64: '',
      isPhoto: '',
      view_item: {},
      UserList: [],
      disabled: true,
      temp_data_json: {},
      types: [],
      RecordEditList: [],
      showRemake: false,
      Reason: '',
      materialTypeList: [],
      ConditionList: [],
      msg_lelve: 0

    }
  },
  watch: {
    // 留言内容监听
    input_value(a) {
      this.sendMsgForm.Message = a
      this.chat_value_length = a.length
    },
    selects(a) {
      this.ApprovalRecordUserDetailsIDList = ''
      if (a.length === 0) return this.ApprovalRecordUserDetailsIDList = ''
      a.map(item => {
        this.ApprovalRecordUserDetailsIDList += item.ApprovalRecordUserDetailsID + ','
      })
    },
    activeName_business(a) {
      if (a === 'third') {
        return window.addEventListener('keydown', this.keyDown)
      }
      if (a !== 'third') return window.removeEventListener('keydown', this.keyDown)
    }
  },
  beforeUpdate() {
    this.$nextTick(() => { // 在数据加载完，重新渲染表格
      this.$refs.multipleTable.doLayout()
    })
  },
  methods: {
    // 获取物料分类
    getInvType() {
      const _this = this
      $post.getInvTypeList().then((res) => {
        _this.materialTypeList = res.Message
      })
    },
    testChange(e) {
      console.log(e.ApprovalTypeString)
      let Status = ''
      e.ApprovalTypeString.forEach(item => {
        Status += `${item},`
      })
      this.searchWords.ApprovalType = Status
      // // const response = await this.$api.GetMyApprovalList(this.searchWords)
      // // this.$nextTick(() => { // 在数据加载完，重新渲染表格
      // //   this.tableData = []
      // //   this.tableData = response.Message
      // //   this.tableTotalSize = response.Total
      // // })
      // // this.$nextTick(() => {
      // //   this.tableData = response.Message
      // //   this.tableTotalSize = response.Total
      // // })
      this.getTableDataAsync()
    },
    filterHandler(value, row, column) {
      const property = column['property']
      console.log(row[property], value)
      return row[property] === value
    },
    showImage(src) {
      console.log(src)
      this.MaterialFileList = []
      this.showViewer = true
      this.MaterialFileList.push(src)
    },
    sueRemake(IDX) {
      this.sendMsgForm.Reason = this.Reason
      this.Reason = ''
      this.$api.RejectApproval({
        IDX: IDX, // this.sendMsgForm.RecordID,
        Reason: this.sendMsgForm.Reason
      }).then(res => {
        this.showRemake = false
        console.log(res)
        if (res.RetCode !== '0') return this.$message.error('服务端错误' + res.RetCode)
        this.showElement = false
        this.refreshCurrentChange()
        return this.$message.success('驳回成功')
      })
    },
    // 查询数量
    GetApprovalListNum() {
      this.$api.GetApprovalListNum().then(res => {
        console.log(res)
        this.numList = res.Message
      })
    },
    selectFile(e) {
      this.$refs.filElem.dispatchEvent(new MouseEvent('click'))
    },
    getFile(e) {
      const inputFile = this.$refs.filElem.files[0]
      console.log('getFilePath', inputFile)
      if (inputFile.type && inputFile.size) {
        var reader = new FileReader()
        //   const url = ''
        reader.readAsDataURL(inputFile)
        this.sendMsgForm.FileExtName = '.' + inputFile.type.split('/')[1]
        // this.MessageType = 0
        reader.onload = (e) => {
          // this.sendMsgForm.Message  =
          this.base64 = ''
          this.base64 = e.target.result
          console.log(e.target.result) // base64
          // this.isPhoto = `☮${inputFile.name}☮`
          // this.input_value += this.isPhoto
          this.sendMsg(true)
        }
      }
    },
    // 回车事件
    keyDown(e) {
      console.log(e.keyCode)
      console.log(e.keyCode === 13 && e.keyCode === 17)
      if (e.keyCode === 13 && e.keyCode === 17) return this.sendMsg()
    },
    // 发送信息
    sendMsg(isFile) {
      if (!isFile) {
        if (!this.input_value) return this.$message.error('内容不能为空')
      }
      if (this.base64) {
        this.sendMsgForm.MessageType = 0
        this.sendMsgForm.Message = this.base64
      } else {
        this.sendMsgForm.MessageType = 1
        this.sendMsgForm.Message = this.input_value
      }
      console.log('msg', this.sendMsgForm)
      this.input_value = ''
      this.$api.SaveApprovalLeaveMessage(this.sendMsgForm).then(res => {
        console.log(res)
        this.sendMsgForm.AtUserID = ''
        this.base64 = null
        if (res.RetCode === '0') {
          this.ChatList.MessageList.push(res.Message)
          return this.$message.success('留言成功')
        }
      })
    },
    async getTableDataAsync(page, pageSize) {
      this.searchWords.PageIndex = page || 1
      this.searchWords.PageSize = this.tablePageSize
      const response = await this.$api.GetMyApprovalList(this.searchWords)
      this.tableData = response.Message
      this.tableTotalSize = response.Total
      this.GetApprovalListNum()
      return response
    },
    async activeNameClick() {
      this.tableLoading = true
      this.searchWords.PageIndex = 1
      this.searchWords.Status = this.activeName * 1
      const response = await this.$api.GetMyApprovalList(this.searchWords)
      this.tableData = response.Message
      this.tableTotalSize = response.Total
      this.tableLoading = false
    },
    // 发起高级搜索
    async searchAd() {
      for (const name in this.formSearch) {
        this.searchWords[name] = this.formSearch[name]
      }
      this.searchWords.BeginTime = this.formSearch.ApprovalDate[0]
      this.searchWords.EndTime = this.formSearch.ApprovalDate[1]
      // delete this.searchWords.ApprovalDate
      // this.searchWords.Status = this.getStatus(this.searchWords.Status)
      // console.log(this.searchWords)
      this.setKeysData(this.searchWords, this.adSearchForm) // 展示搜索条件
      const response = await this.$api.GetMyApprovalList(this.searchWords)
      this.tableData = response.Message
      this.tableTotalSize = response.Total
      this.isShow = false
    },
    getStatus(string) {
      switch (string) {
        case '全部':
          return 0
        case '待审':
          return 1
        case '已审':
          return 2
        case '我发起的':
          return 3
        case '抄送我的':
          return 4
        default:
          return '0'
      }
    },
    Adsearch() {
      //  弹出高级搜索弹框
      this.isShow = true
    },
    selectAll(e) {
      this.selects = e
    },
    select(row) {
      this.selects = row
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 获取储存 运输条件
    getConditionList() {
      const _this = this
      $post.getConditionList().then((res) => {
        _this.ConditionList = res.Message
      })
    },
    viewItem(row) {
      console.log(row)
      this.formJson = JSON.parse(row.FormJson)
      this.dataJson = JSON.parse(row.DataJson)
      if (this.dataJson.ShelfLifeType) {
        this.$set(this.dataJson, 'isLong', false)
      }
      if (!this.dataJson.ShelfLifeType) {
        this.$set(this.dataJson, 'isLong', true)
      }
      console.log('this.dataJson', this.dataJson)
      this.view_item = row
      console.log(this.formJson, this.dataJson)
      this.showElement = true
      this.sendMsgForm.RecordID = row.IDX
      this.getInvType()
      this.getConditionList()
      this.GetApprovalRecordList(row.IDX)
      this.GetApprovalLeaveMessageList(row.IDX)
      this.GetApprovalRecordInfo(row.IDX)
      this.GetApprovalUserList(row.IDX)
      this.GetCertificateType(this.view_item.ApprovalType)
      this.GetApprovalRecordEditList(row.IDX)
      this.GetApprovalLeaveMessageNewNum(row.IDX)
    },
    // 查询留言未读数量
    GetApprovalLeaveMessageNewNum(RecordID) {
      this.$api.GetApprovalLeaveMessageNewNum({ RecordID }).then(res => {
        if (res.RetCode === '0' && res.Message > 0) {
          console.log('留言数量', res)
          this.msg_lelve = res.Message
        }
      })
    },
    // 重置已读状态
    SaveApprovalLeaveMessageViewTime() {
      this.$api.SaveApprovalLeaveMessageViewTime({ IDX: this.view_item.IDX }).then(res => {
        console.log('重置已读状态', res)
      })
    },
    // 获取证照类型
    GetCertificateType(ApprovalType) {
      if (ApprovalType === 1) {
        this.$api.GetCertificateType({
          CertificateType: 1
        }).then(res => {
          console.log('企业证照类型', res)
          this.types = res.Message
        })
      }
      if (ApprovalType === 2) {
        this.$api.getFileTypes().then(res => {
          console.log('产品证照类型', res)
          this.types = res.Message
        })
      }
    },
    handleClick(e) {
      if (e.name === 'third') {
        this.SaveApprovalLeaveMessageViewTime()
      }
    },
    // 通过审核
    AgreeApprovals(IDX) {
      console.log(IDX)
      this.isBTn = true
      if (!IDX) {
        if (!this.ApprovalRecordUserDetailsIDList) return this.$message.error('没有任何选中！')
      }
      this.$api.AgreeApproval({
        ApprovalRecordUserDetailsIDList: IDX || this.ApprovalRecordUserDetailsIDList
      }).then(res => {
        this.isBTn = false
        if (res.RetCode !== '0') return this.$message.error('服务端错误' + res.RetMsg)
        this.showElement = false
        this.$message.success('审核成功')
        this.refreshCurrentChange()
        return
      })
    },
    // 获取审批日志
    GetApprovalRecordList(ApprovalRecordID) {
      this.$api.GetApprovalRecordList({
        ApprovalRecordID
      }).then(res => {
        console.log(res)
        this.RecordList = res.Message
      })
    },
    // 留言记录
    GetApprovalLeaveMessageList(RecordID) {
      this.$api.GetApprovalLeaveMessageList({
        RecordID
      }).then(res => {
        console.log(res)
        this.ChatList = res.Message
      })
    },
    // 审批流程
    GetApprovalRecordInfo(RecordID) {
      this.$api.GetApprovalRecordInfo({
        RecordID
      }).then(res => {
        console.log(res)
        this.RecordInfo = res.Message
      }).catch(err => console.log(err))
    },
    // 导出审批日志
    DownApprovalRecordList() {
      this.$api.DownApprovalRecordList({
        IDX: this.view_item.IDX
      }).then(res => {
        if (res.RetCode === '0') {
          console.log(res)
          this.$minCommon.downloadFile(res.Message)
          return this.$message.success('导出成功')
        }
      })
    },
    // 重新发起
    restart() {
      // 产品审核
      console.log('this.view_item', this.view_item)
      if (this.view_item.ApprovalType === 2) {
        this.$router.push({ name: 'productEdit1', params: { idx: this.view_item.IDX, type: 1 }})
      }
      if (this.view_item.ApprovalType === 1) {
        this.$router.push({ name: 'Merchants', params: { view_item: this.view_item, from: 'myApproval' }})
      }
      // this.$router.push({ name: 'productEdit1', params: { idx: this.view_item.IDX, type: 1 }})
    },
    // 驳回
    RejectApproval(IDX) {
      this.showRemake = true
    },
    // 下载证件
    downCampZip(FileJsonStr) {
      console.log('this.RecordInfo', this.RecordInfo)
      if (!FileJsonStr && !this.RecordInfo.FileJsonStr) {
        return this.$message.error('没有可下载的证件')
      }
      this.$api.DownloadFirstCampZip({
        JsonStr: FileJsonStr || this.RecordInfo.FileJsonStr
      }).then(res => {
        this.$minCommon.downloadFile(res.Message, '.zip')
      })
    },
    // 查询修改日志
    GetApprovalRecordEditList(RecordID) {
      this.$api.GetApprovalRecordEditList({
        RecordID
      }).then(res => {
        console.log(res)
        this.RecordEditList = res.Message
      })
    },
    // @人列表
    GetApprovalUserList(RecordID) {
      this.$api.GetApprovalUserList({
        RecordID
      }).then(res => {
        console.log(res)
        this.UserList = res.Message
      })
    },
    // 选择@人
    select_user(row) {
      console.log(row) // UserID
      this.sendMsgForm.AtUserID += `${row.UserID},`
      this.input_value += `@${row.NickName}`
    },
    // 修改表单
    setEdit() {
      this.disabled = false
      this.temp_data_json = JSON.parse(JSON.stringify(this.dataJson)) // 将dataJson暂存起来
    },
    saveForm() {
      // 调用接口保存数据
      // this.dataJson.CompanyType = this.dataJson.CompanyTypeString
      if (Array.isArray(this.dataJson.CertList)) {
        this.dataJson.CertList.map(item => {
          item.ValidTo = item.ValidToString
        })
      }

      console.log(' this.dataJson', this.dataJson) // 新的表单数据\
      this.$api.SaveApprovalContent({
        Type: this.view_item.ApprovalType,
        RecordID: this.view_item.IDX,
        DataJson: JSON.stringify(this.dataJson)
      }).then(res => {
        console.log(res)
        if (res.RetCode === '0') {
          this.refreshCurrentChange()
          this.GetApprovalRecordEditList(this.view_item.IDX)
          return this.$message.success('修改成功')
        }
        return this.$message.success('修改失败' + res.RetMsg)
      })
      // eslint-disable-next-line no-unreachable
      this.disabled = true
    },
    // 返回
    goBack() {
      this.disabled = true
      this.dataJson = this.temp_data_json
    },
    // 修改有效期
    changelong(val) {
      console.log(val)
      if (val) {
        this.dataJson.ShelfLifeType = 0
        this.dataJson.isLong = true
        // this.dataJson  dataJson['isLong']
        // this.dataJson.ShelfLifeNum = null
      } else {
        this.dataJson.ShelfLifeType = null
        // this.dataJson.ShelfLifeNum = null
        this.dataJson.isLong = false
      }
    },
    // 修改证照名称
    isChange(e, index) {
      console.log(e, index)
      const i = this.types.filter(item => item.IDX === e)
      if (i.length > 0) this.dataJson.CertList[index].TypeID = i[0].IDX
      // this.dataJson.CertList[index].TypeID = i[0].Code
    },
    dateChange(time) {
      const time1 = new Date(time).getTime()
      // return time.getTime()
      console.log(time1)
    },
    // 导出
    exportFile() {
      this.$api.DownMyApprovalList(
        this.searchWords
      ).then(res => {
        console.log(res)
        this.$minCommon.downloadFile(res.Message, '.xls')
      })
    }

  }
}

export default myApproval
