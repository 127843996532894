<template>
  <div class="my-approval">
    <div class="listHeader  SecondNav">
      <el-tabs v-model="activeName" type="card" @tab-click="activeNameClick">
        <el-tab-pane :label="`全部 （${numList.AllNum ? numList.AllNum : 0 }）`" name="0" />
        <el-tab-pane :label="`待审批（${numList.WaitNum ? numList.WaitNum : 0}）`" name="1" />
        <el-tab-pane :label="`已审批（${numList.AdoptNum ? numList.AdoptNum : 0}）`" name="2" />
        <el-tab-pane :label="`我发起的（${numList.LaunchNum ? numList.LaunchNum : 0}）`" name="3" />
        <el-tab-pane :label="`抄送我的（${numList.CopyNum ? numList.CopyNum : 0}）`" name="4" />

      </el-tabs>
    </div>
    <div class="searchView flex a-center m-bottom-10 j-between p-lr-20">
      <div class="flex">
        <!-- <div
          v-if="$minCommon.checkMeau('spzxexport', userBottons)"
          class="out flex a-center m-right-10 pointer ModelBttton-white-30"
          @click="exportFile"
        >
          <i class="el-icon-upload2 f15 p-right-10" /> 导出
        </div> -->
        <div
          v-if="$minCommon.checkMeau('spzxpassexamine', userBottons) && activeName==='1'"
          class="out flex a-center m-right-10 cursor ModelBttton-white-30"
          @click="AgreeApprovals(false)"
        >
          <i class="el-icon-s-check f15 p-right-5" /> 批量通过审核
        </div>
      </div>
      <div class="flex SearchBox_30" style="flex: 1">
        <el-input
          v-model="searchWords.KeyWords"
          size="medium"
          placeholder="可通过审批编号、部门名称搜索"
          class="rule-input-edit"
        >
          <template slot="prepend">
            <div class="pointer">
              <i class="el-icon-s-operation" @click="Adsearch" />
              <span @click="Adsearch">高级搜索</span>
            </div>
          </template>
          <div
            slot="append"
            @click="searchGetTableDataAsync"
          >
            搜 索
          </div>
        </el-input>
      </div>
    </div>
    <SearchKeys v-if="isProductCjq" class="m-lr-20" :search-keys="searchKeys" @close="closeTag($event,searchWords,adSearchForm,'ApprovalDate','BeginTime','EndTime')" />

    <div class="p-lr-20">
      <el-table
        ref="multipleTable"
        v-loading="tableLoading"
        :header-cell-style="headerCStyle"
        :data="tableData"
        height="calc(100vh - 315px)"
        tooltip-effect="dark"
        show-header
        row-key="id"
        border
        fit
        @select-all="selectAll"
        @select="select"
        @selection-change="handleSelectionChange"
        @filter-change="testChange"
      >

        <!-- @filter-change="testChange" -->
        <af-table-column fixed="left" type="selection" width="40" />
        <af-table-column prop="ApprovalCode" label="审批编号" />
        <el-table-column
          width="100"
          prop="ApprovalType"
          label="审批类型"
          align="left"
          show-overflow-tooltip
          column-key="ApprovalTypeString"
          :filter-method="filterHandler"
          :filters="[
            { text: '企业首营', value: 1 },
            { text: '产品首营', value: 2 }
          ]"
        >
          <template slot-scope="scope">
            <!--  @filter-change="testChange" <el-button v-if="scope.row.ApproveState === 2" size="mini" type="success" icon="el-icon-check" circle />
              <el-button v-if="scope.row.ApproveState === 4" type="info" icon="el-icon-minus" circle /> -->
            {{ scope.row.ApprovalTypeString }}
          </template>
        </el-table-column>
        <af-table-column
          prop="ApprovalName"
          label="审批名称"
          align="left"
          show-overflow-tooltip
        />
        <af-table-column
          prop="ApprovalApplyDepartment"
          label="申请部门"
          show-overflow-tooltip
        />
        <af-table-column
          prop="ApprovalApplyUser"
          label="申请人"
          show-overflow-tooltip
        />
        <af-table-column
          prop="BeginTimeString"
          label="发起时间"
          show-overflow-tooltip
        />
        <af-table-column prop="EndTimeString" label="完成时间" show-overflow-tooltip />
        <af-table-column prop="StatusString" label="当前状态" show-overflow-tooltip />
        <el-table-column fixed="right" prop="address" align="center" label="操作" width="50">
          <!-- slot-scope="scope" -->
          <template slot-scope="scope">
            <el-popover
              placement="left"
              trigger="hover"
              :width="50"
              popper-class="test_pop_view"
            >
              <div class="DropDownList">
                <div v-if="$minCommon.checkMeau('spzxview', userBottons)" class="OperationButton" @click="viewItem(scope.row)">
                  <el-link :underline="false"><i class="el-icon-view p-right-10" />查看</el-link>
                </div>
                <div v-if="$minCommon.checkMeau('spzxdownload', userBottons)" class="OperationButton">
                  <el-link :underline="false" @click="downCampZip(scope.row.FileJsonStr)"><i class="el-icon-download p-right-10" />下载</el-link>
                </div>
              </div>
              <i slot="reference" class="el-icon-more" />
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          :ref="paginationName"
          :total="tableTotalSize"
          :pager-count="pagerCount"
          :page-size="tablePageSize"
          :background="tableBackground"
          :current-page="currentPage"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange($event)"
        />
      </div>
    </div>
    <Adsearch :form="formSearch" :ad-search-form="adSearchForm" :show="isShow" @cancel="(e) => isShow = e" @searchAd="searchAd" />
    <el-dialog top="5vh" width="90%" custom-class="custom-class1" class="dialogClass_ck" :show-close="false" :visible="showElement">
      <PageHeader :title="`${view_item.ApprovalTypeString}申请单`"><i class="el-icon-close cursor" @click="() => showElement = false" /></PageHeader>
      <div class="main_business flex j-between">
        <!-- 左侧 -->
        <div class="main_business_left flex column">
          <div class="top_process flex a-center  min-border-bottom">
            <div v-for="(i,n) in process_list" :key="n" class="top_process_item flex j-left a-center" :class="i.value !== '6' ? 'min-border-right' :'' ">
              <div class="point">
                <i :class="`${i.icon} f25`" />
              </div>
              <div class="flex j-center a-left column p-left-10 f12">
                <div class="weight">{{ i.name }}</div>
                <div class="hui">{{ RecordInfo.RecordInfo[i.value] }}</div>
              </div>
            </div>
          </div>
          <!-- 驳回原因 -->
          <div v-if="RecordInfo.RecordInfo.Status === 2" class="no_reson p-tb-20 p-lr-30 theme-color">
            驳回原因: {{ RecordInfo.RecordInfo.Reason }}
          </div>
          <div class="mid_process">
            <div
              class="flex column p-tb-10 p-lr-10"
              style="background-color: #fff; flex: 1;height:auto"
            >
              <div v-for="(test,index) in formJson" :key="index" class="right1 p-lr-10 ">
                <div class="InfoTitleBox m-tb-10"><i class="el-icon-caret-right" />{{ test.Name }}</div>
                <el-form :hide-required-asterisk="false" size="small" :label-width="test.Name === '企业证照' ? '0' : '100px'">
                  <div
                    class="j-between"
                    style="display: flex;flex-wrap: wrap;"
                  >
                    <template v-for="(item,ia) in test.Value">
                      <el-form-item v-if="item.IsShow" :key="ia" :required="item.Disabled" :style="{width: test.Name === '企业证照' ? '100%' : item.Colspan === 1 ? '30%' :item.Colspan === 2 ? '60%' : '100%',height:'auto'}" :label="item.IsShow ? item.Label === '默认占位' ? '' : item.Label : '' ">
                        <template
                          v-if=" test.Name === '企业证照' &&
                            item.Label !== '默认占位'"
                        >
                          <div class="flex " style="width:100%;flex-wrap: wrap;">
                            <div v-for="(info, index_) in dataJson.CertList" :key="info.index_" class="flex j-center  m-bottom-20">
                              <el-image :src="`${b2bstaticUrl}${info.PhotoUrl}`" style="width:200px;height:200px;" @click="showImage(`${b2bstaticUrl}${info.PhotoUrl}`)" />
                              <div class="m-lr-20">
                                <div class="flex">
                                  <span style="width:100px">证件名称:</span>
                                  <el-select v-model="info.TypeID" :disabled="disabled" style="width:100%" size="small" placeholder="请选择" @change="isChange($event, index_)">
                                    <el-option
                                      v-for="item1 in types"
                                      :key="item1.Code"
                                      :label="item1.Name"
                                      :value="item1.Code"
                                    />
                                  </el-select>
                                  <!-- <el-input v-model="" size="mini" :disabled="disabled" /> -->
                                </div>
                                <div v-if="info.CertificateCode" class=" flex p-top-10">
                                  <span style="width:100px">证件编号:</span>
                                  <el-input v-model="info.CertificateCode" size="mini" :disabled="disabled" />
                                </div>
                                <div v-if="info.CompanyName" class="flex p-tb-10">
                                  <span style="width: 100px">机构名称:</span>
                                  <el-input
                                    v-model="info.CompanyName"
                                    size="mini"
                                    :disabled="disabled"
                                  />
                                </div>
                                <div v-if="info.Address && view_item.ApprovalType === 1" class="flex p-top-10">
                                  <span style="width: 100px">库房生产地址:</span>
                                  <el-input
                                    v-model="info.Address"
                                    size="mini"
                                    :disabled="disabled"
                                  />
                                </div>
                                <!-- view_item.ApprovalTypeString  产品 证件起始日期-->
                                <div v-if="view_item.ApprovalType === 2 && info.beginDate" class="flex p-top-10">
                                  <span style="width: 100px">起始日期:</span>
                                  <el-date-picker
                                    v-model="info.beginDate"
                                    type="date"
                                    size="mini"
                                    style="width: 239px"
                                    value-format="yyyy-MM-dd"
                                    :disabled="disabled"
                                    placeholder="选择日期"
                                  />
                                </div>
                                <div class="flex p-top-10">
                                  <span style="width: 100px">备注:</span>
                                  <el-input
                                    v-model="info.Remark"
                                    size="mini"
                                    :disabled="disabled"
                                  />
                                </div>
                                <div v-if="info.ValidToString" class="flex p-top-10">
                                  <span style="width:80px">有效期至:</span>
                                  <div style="200px">
                                    <el-date-picker
                                      v-model="info.ValidToString"
                                      :clearable="false"
                                      type="date"
                                      placeholder="选择日期"
                                      :disabled="disabled"
                                      size="mini"
                                      style="width: 239px"
                                      value-format="yyyy-MM-dd"
                                    />
                                  </div>

                                  <!-- <el-input v-model="" size="mini" :disabled="disabled" /> -->
                                </div>
                              </div>
                            </div>
                          </div>

                        </template>
                        <el-input v-if=" test.Name !== '企业证照' && item.Label !== '默认占位' && item.Label !== '开户支行' && item.Label !== '产品税率' && item.Label !== '物料默认所在仓库' && item.Label !== '运输条件' && item.Label !== '储存条件' && item.Label !== '注册证截止日期' && item.Label !== '产品有效期' && item.Label !== '注册证起始日期' && item.Label !== '物料分类' && item.Label !== '企业类型' && item.Label !== '开票税率' && item.Label !== '详细地址' && item.Label !== '物料类型' && item.Label !== '付款方式' && item.Label !== '回款账期(天)'" v-model="dataJson[item.Key]" :disabled="disabled" autocomplete="off" />
                        <el-input v-if="item.Label == '详细地址' " :value="`${dataJson['Province']}${dataJson['City']}${dataJson['Area']}${dataJson[item.Key]}`" :disabled="true" autocomplete="off" />
                        <el-input v-if="item.Label == '回款账期(天)' " v-model="dataJson['PaymentCollectionDays']" :disabled="disabled" :controls="false" label="请输入天数" type="number" />
                        <el-input v-if="item.Label == '开户支行' " :value="`${dataJson['BranchBankProvince']}${dataJson['BranchBankCity']}${dataJson['BranchBankArea']}${dataJson['BranchBank']}`" :disabled="true" autocomplete="off" />

                        <el-select v-if="item.Label == '企业类型'" v-model="dataJson['CompanyType']" :disabled="disabled" size="mini" placeholder="请选择">
                          <el-option
                            v-for="item1 in [{value:1, label:'客户'},{value:2, label:'供应商'},{value:4, label:'客户-供应商'}]"
                            :key="item1.value"
                            :label="item1.label"
                            :value="item1.value"
                          />
                        </el-select>
                        <!-- 物料类型 -->
                        <el-select v-if="item.Label == '物料类型'" v-model="dataJson['MaterialTypeID']" :disabled="disabled" placeholder="">
                          <el-option label="类型1" :value="1" />
                          <el-option label="类型2" :value="2" />
                        </el-select>
                        <!-- 物料分类 -->
                        <el-select v-if="item.Label == '物料分类'" v-model="dataJson['InvTypeID']" :disabled="disabled" placeholder="" filterable>
                          <el-option v-for="matertypeitem in materialTypeList" :key="matertypeitem.IDX" :label="matertypeitem.InvTypeName" :value="matertypeitem.IDX" />
                        </el-select>
                        <!-- 注册证起始有效期 -->
                        <el-date-picker
                          v-if="item.Label == '注册证起始日期'"
                          v-model="dataJson['RegisterStart']"
                          :clearable="false"
                          :disabled="disabled"
                          type="date"
                          style="width:100%"
                          value-format="yyyy-MM-dd"
                          placeholder=""
                        />
                        <!-- 注册证截止日期 -->
                        <el-date-picker
                          v-if="item.Label == '注册证截止日期'"
                          v-model="dataJson['RegisterEnd']"
                          :disabled="disabled"
                          type="date"
                          style="width:100%"
                          value-format="yyyy-MM-dd"
                          placeholder=""
                        />
                        <!-- 运输条件 -->
                        <el-select v-if="item.Label == '运输条件'" v-model="dataJson['TransportCondition']" :disabled="disabled" placeholder="" filterable>
                          <el-option v-for="anc in ConditionList" :key="anc.IDX" :label="anc.StorageConditionName" :value="anc.IDX" />
                        </el-select>
                        <!-- 储存条件 -->
                        <el-select v-if="item.Label == '储存条件'" v-model="dataJson['StorageConditionID']" :disabled="disabled" placeholder="" filterable>
                          <el-option v-for="a in ConditionList" :key="a.IDX" :label="a.StorageConditionName" :value="a.IDX" />
                          <el-option label=" " :disabled="true" :value="0" />
                        </el-select>
                        <!-- 产品有效期 -->
                        <el-input
                          v-if="!dataJson['isLong'] && item.Label == '产品有效期'"
                          v-model="dataJson['ShelfLifeNum']"
                          size="mini"
                          style="width:45px"
                          placeholder=""
                          :disabled="disabled"
                        />
                        <el-select
                          v-if="!dataJson['isLong'] && item.Label == '产品有效期'"
                          v-model="dataJson['ShelfLifeType']"
                          placeholder=""
                          style="width:65px"
                          :disabled="disabled"
                        >
                          <el-option label="年" :value="1" />
                          <el-option label="月" :value="2" />
                          <el-option label="日" :value="3" />
                        </el-select>
                        <el-checkbox v-if="item.Label == '产品有效期'" v-model="dataJson['isLong']" :disabled="disabled" style="margin-left:15px" @change="changelong">长期有效</el-checkbox>
                        <!-- 产品有效期 -->
                        <!-- 物料类型 -->
                        <!-- <el-select v-if="item.Label == '物料类型'" v-model="dataJson['TransportCondition']" :disabled="disabled" placeholder="" filterable>
                          <el-option v-for="anc in ConditionList" :key="anc.IDX" :label="anc.StorageConditionName" :value="anc.IDX" />
                        </el-select> -->
                        <!-- 物料默认所在仓库 -->
                        <el-select v-if="item.Label == '物料默认所在仓库'" v-model="dataJson['StockCode']" :disabled="disabled" placeholder="" filterable>
                          <el-option label="3000" :value="3000" />
                        </el-select>
                        <!-- 产品税率 -->
                        <el-select v-if="item.Label == '产品税率'" v-model="dataJson['Tax']" :disabled="disabled" placeholder="">
                          <el-option label="17%" :value="0.17" />
                          <el-option label="16%" :value="0.16" />
                          <el-option label="13%" :value="0.13" />
                          <el-option label="11%" :value="0.11" />
                          <el-option label="10%" :value="0.1" />
                          <el-option label="6%" :value="0.06" />
                          <el-option label="3%" :value="0.03" />
                          <el-option label="1%" :value="0.01" />
                          <el-option label="0%" :value="0" />
                        </el-select>
                        <el-select v-if="item.Label == '付款方式'" v-model="dataJson['PayType']" :disabled="disabled" size="mini" placeholder="请选择">
                          <el-option label="货到付款" value="货到付款" />
                          <el-option label="先款后货" value="先款后货" />
                          <el-option label="月结" value="月结" />
                          <el-option label="先付定金，货到付余款" value="先付定金，货到付余款" />
                          <el-option label="其他" value="其他" />
                        </el-select>
                        <el-select v-if="item.Label == '开票税率'" v-model="dataJson['TaxRate']" :disabled="disabled" size="mini" placeholder="请选择">
                          <el-option label="17%" :value="0.17" />
                          <el-option label="16%" :value="0.16" />
                          <el-option label="13%" :value="0.13" />
                          <el-option label="11%" :value="0.11" />
                          <el-option label="10%" :value="0.10" />
                          <el-option label="6%" :value="0.06" />
                          <el-option label="3%" :value="0.03" />
                          <el-option label="1%" :value="0.01" />
                          <el-option label="0%" :value="0" />
                          <el-option label="   " :value="-1" disabled />
                        </el-select>
                      </el-form-item>
                    </template>
                  </div>
                </el-form>
              </div>
            </div>
          </div>
          <div class="btm_process flex j-end a-center min-border-top p-right-5" style="box-sizing: border-box;">
            <el-button size="mini" icon="el-icon-download" @click="downCampZip(false)">打包下载证件</el-button>
            <el-button v-if="view_item.ApprovaPersonStatus === 2 && view_item.NodeType === 0" size="mini" type="warning" icon="el-icon-refresh" @click="restart">重新发起</el-button>
            <el-button v-if="view_item.AllowEdit && disabled && view_item.ApprovaPersonStatus === 0" size="mini" icon="el-icon-edit" @click="setEdit">修改</el-button>
            <el-button v-if="view_item.ApprovaPersonStatus === 0 && view_item.NodeType === 1" size="mini" type="danger" icon="el-icon-s-release" @click="RejectApproval(view_item.ApprovalRecordUserDetailsID)">驳回</el-button>
            <el-button v-if="view_item.ApprovaPersonStatus === 0 && view_item.NodeType === 1" :loading="isBTn" size="mini" type="primary" icon="el-icon-s-check" @click="AgreeApprovals(view_item.ApprovalRecordUserDetailsID)">通过审核</el-button>
            <el-button v-if="!disabled" size="mini" type="primary" icon="el-icon-folder-checked" @click="saveForm">保存</el-button>
            <el-button v-if="!disabled" size="mini" icon="el-icon-bottom-left" @click="goBack">返回</el-button>
            <div class="p-right-5" />
          </div>
        </div>
        <!-- 右侧 -->
        <div class="main_business_right min-border-left">
          <div class="top_process min-border-bottom">
            <el-tabs v-model="activeName_business" class="tabs_cjq" @tab-click="handleClick">
              <el-tab-pane label="审批日志" name="first">
                <div v-if="view_item.AllowJournalView " class="SpeedProgress">
                  <el-timeline style="padding:5px">
                    <el-timeline-item
                      v-for="(activity, index) in RecordList"
                      :key="index"
                      :timestamp="activity.UpdateTimeString"
                      placement="top"
                    >
                      <div class="card_bussness flex j-center column">
                        <div class="top flex column j-between  p-lr-10">
                          <div class="flex  j-between  p-bottom-5">
                            <div style="color:#909399">{{ activity.NodeTypeString }}</div>
                            <!-- el-icon-s-order -->
                            <i :class="activity.NodeType === 0 ? 'el-icon-tickets' : activity.NodeType === 1 ? 'el-icon-s-check' :'el-icon-position'" style="color:#096dcd" />
                          </div>
                          <div class="bg_hui">
                            <div v-if="activity.NodeType === 0">
                              <el-tooltip placement="bottom">
                                <div slot="content">
                                  <div v-for="i in activity.ApprovalUserList" :key="i.IDX">
                                    {{ i.Name }}<br>
                                    {{ i.Mobile }}
                                  </div>
                                </div>
                                <i class="el-icon-s-custom f16" style="color:#ccc" />
                              </el-tooltip>
                              <span class="p-left-10">{{ activity.ApprovalUserList[0].Name }} 发起新的</span>
                            </div>
                            <div v-else class="flex">
                              <el-tooltip placement="bottom">
                                <div slot="content">
                                  <div v-for="i in activity.ApprovalUserList" :key="i.IDX">
                                    {{ i.Name }}<br>
                                    {{ i.Mobile }}<br>
                                  </div>
                                </div>
                                <i class="el-icon-s-custom f16" style="color:#ccc" />
                              </el-tooltip>
                              <div v-if="activity.ApprovalUserList.length >= 1" class="flex p-left-10 el-1">
                                {{ activity.ApprovalUserList[0].Name }} <span v-if=" activity.ApprovalUserList.length > 1" class="el-1">等人</span> {{ activity.ApprovalUserList[0].ApprovaPersonStatusString }}
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </el-timeline-item>
                  </el-timeline>
                  <div class="btn flex j-center a-center">
                    <el-button size="mini" @click="DownApprovalRecordList">导出审批日志</el-button>
                  </div>
                </div>
                <div v-else>
                  暂无权限
                </div>
              </el-tab-pane>
              <el-tab-pane label="修改日志" name="second">
                <div v-if="view_item.AllowEdit">
                  <el-timeline style="padding:5px">
                    <el-timeline-item
                      v-for="(activity, index) in RecordEditList"
                      :key="index"
                      :timestamp="activity.WriteTimeString"
                      placement="top"
                    >
                      <div class="card_bussness flex j-center column">
                        <div class="top flex column j-between  p-lr-20">
                          <div class="flex  j-between  min-border-bottom p-bottom-5">
                            <div style="color:#909399">{{ activity.NickName }}</div>
                            <!-- el-icon-s-order -->
                            <i class="el-icon-tickets" style="color:#096dcd" />
                          </div>
                          <div class=" p-top-5 weight">
                            {{ activity.EditContent }}
                          </div>
                        </div>
                      </div>
                    </el-timeline-item>
                  </el-timeline>
                  <div class="btn flex j-center a-center">
                    <el-button size="mini" @click="DownApprovalRecordList">导出修改日志</el-button>
                  </div>
                </div>
                <div v-else>
                  暂无权限
                </div>
              </el-tab-pane>
              <el-tab-pane label="留言" name="third">
                <div v-if="view_item.AllowLeaveMessage" class="chat flex column">
                  <div class="content">
                    <div class="content_view p-lr-20">
                      <div v-for="(chat, ina) in ChatList.MessageList" :key="ina">
                        <!-- 左侧 别人 -->
                        <div v-if="ChatList.UserID !== chat.UserID" class="left_chat">
                          <div class="item_chat  p-tb-10">
                            <div class="p-tb-10 f12" style="color:#999;text-align:left"><i class="el-icon-user-solid" /> {{ chat.NickName }} {{ chat.WriteTimeString }}</div>
                            <div v-if="!chat.MessageType" class="item_content">
                              <el-image :src="chat.Message" @click="showImage(chat.Message)" />
                            </div>
                            <div v-if="chat.MessageType" class="item_content_message">
                              {{ chat.Message }}
                            </div>
                          </div>
                        </div>
                        <!-- 左侧 自己 -->
                        <div v-if="ChatList.UserID === chat.UserID" class="right_chat">
                          <div class="item_chat  p-tb-10">
                            <div class="p-tb-10 f12" style="color:#999;text-align:right">{{ chat.NickName }}{{ chat.WriteTimeString }}<i class="el-icon-user-solid" /> </div>
                            <div v-if="!chat.MessageType" class="item_content">
                              <el-image :src="chat.Message" @click="showImage(chat.Message)" />
                            </div>
                            <div v-if="chat.MessageType" class="item_content_message">
                              {{ chat.Message }}
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="send_view flex column">
                    <div class="input_view">
                      <el-input v-model="input_value" type="textarea" :rows="2" resize="none" placeholder="点击发送消息" maxlength="140" />
                    </div>
                    <div class="btm flex a-center j-between p-lr-10">
                      <div>
                        <i class="el-icon-folder p-right-5" @click="selectFile">
                          <input id="file" ref="filElem" style="filter:alpha(opacity=0);opacity:0;width: 0;height: 0;" type="file" class="upload-file" @change="getFile">
                        </i>
                        <el-popover
                          placement="top"
                          trigger="click"
                          :width="50"
                          popper-class="test_pop_view"
                        >
                          <div>
                            <div v-for="(i,n) in UserList" :key="n" class="cursor" @click.stop="select_user(i)">
                              {{ i.NickName }}
                            </div>
                          </div>
                          <span slot="reference">@</span>
                        </el-popover>

                      </div>
                      <div>
                        <span class="f12 p-right-10" style="color:#ccc">{{ chat_value_length }} /140</span>
                        <span class="weight f14 cursor" style="color:#096dcd;" @click="sendMsg(false)">发送</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  暂无权限
                </div>
              </el-tab-pane>

            </el-tabs>
            <el-badge v-if="msg_lelve" :value="msg_lelve" :max="999" class="badge_cjq" />
          </div>

        </div>
      </div>
    </el-dialog>
    <!-- 修改备注 start -->
    <el-dialog :visible.sync="showRemake" custom-class="dialog_view1" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <p class="p-left-20 weight">驳回原因</p>
        <p class="p-right-10 weight f16 cursor" style="color:#000" @click="() => showRemake = false">x</p>
      </div>
      <div style="height:30px" />
      <el-input
        v-model="Reason"
        type="textarea"
        :autosize="{ minRows: 4, maxRows: 8}"
        placeholder="请输入内容"
      />
      <div style="height:30px" />
      <div class="footers min-border-top flex a-center j-center p-tb-20">
        <el-button size="mini" round @click="() => showRemake = false">取消</el-button>
        <el-button size="mini" type="primary" round @click="sueRemake(view_item.ApprovalRecordUserDetailsID)">确定</el-button>
      </div>
    </el-dialog>
    <!-- 修改备注 end -->
    <el-image-viewer v-if="showViewer" style="z-index:9999" :url-list="MaterialFileList" :on-close="() => showViewer = false" />
  </div>
</template>

<script>
import myApproval from '@/minxin/merchants/myApproval.js'
export default myApproval
</script>

<style scoped lang="scss">
@import "@/style/search.scss";
@import "@/style/business.scss";

    .right1{
      // width: 100%;
      border-radius:5px ;
      height: auto;
      margin: 0 0 10px 0;
        background-color: #FFF;
    }
</style>
<style >
.input_view {
   flex: 1;
}
input.el-textarea__inner {
  border: none;
  padding: 0;
 }
.textarea1 .el-input__inner{
   padding: 2px;
}
.top_process{
  position: relative;
}
.badge_cjq {
  position: absolute;
  right: 50px;
  top: 5px;
}
 .dialogClass_ck .el-dialog__header {
  display: none;
}
.dialogClass_ck .el-dialog__body {
  padding: 0;
}
.dialogClass_ck .point{ display: inline-block; background-color:#4DA6FF; color: #fff; border-radius: 4px; width: 30px; line-height: 30px; text-align: center; margin-left:10px;}
.dialogClass_ck .point i{font-size: 12px;}
.j-between .el-form-item{ margin-bottom: 10px;}
</style>
